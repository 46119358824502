<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>基础设置</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/startDiagram' }">启动图设置</el-breadcrumb-item>
                <el-breadcrumb-item>新增</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
            <el-form ref="form" :model="form" label-width="120px" size="mini" :rules="rules">

                <el-form-item label="名称：" label-position="left" prop="name">
                    <el-input v-model="form.name" style="width: 300px"></el-input>
                </el-form-item>

                <el-form-item label="启动图：" label-position="left" prop="pic">
                    <select-images @onSelect="onSelect" @onDelete="onDelete" :image="image"></select-images>
                </el-form-item>

                <el-form-item label="是否节假日：" label-position="left" class="input" prop="is_holiday">
                    <el-select style="width: 200px" v-model="form.is_holiday" placeholder="请选择">
                        <el-option label="是" :value="1"></el-option>
                        <el-option label="否" :value="2"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="状态：" label-position="left" class="input" prop="status">
                    <el-switch v-model="form.status" @change="setStatus"> </el-switch>
                    <span style="margin-left: 5px; font-size: 12px">{{ statusTxt }}</span>
                </el-form-item>

                <el-form-item label="开始时间：" label-position="left" class="input" prop="start_time" v-if="form.is_holiday==1">
                    <el-date-picker  value-format="yyyy-MM-dd HH:mm:ss"  v-model="form.start_time" type="datetime"
                        placeholder="选择开始时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间：" label-position="left" class="input" prop="end_time" v-if="form.is_holiday==1">
                    <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" v-model="form.end_time" type="datetime"
                        placeholder="选择结束时间">
                    </el-date-picker>
                </el-form-item>

                <el-form-item>
                    <el-button size="small" type="primary" :loading="loading" @click="onSubmit">保存</el-button>
                    <el-button style="margin-left: 10px" size="small" @click="$router.go(-1)">取消</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import SelectImages from "../../../components/selectImages/index";
export default {
    name: "add",
    components: { SelectImages },
    data() {
        return {
            statusTxt: "启用",
            imageUrl: config.imageUrl,
            loading: false,
            image: "",
            form: {
                type: 1,
                status: true,
                pic: "",
                name: "",
            },
            rules: {
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                pic: [
                    { required: true, message: '请选择图片', trigger: 'change' },
                ],
                is_holiday: [
                    { required: true, message: '请选择是否节假日', trigger: 'change' },
                ],
                start_time: [
                    { required: true, message: '请选择开始时间', trigger: 'change' },
                ],
                end_time: [
                    { required: true, message: '请选择结束时间', trigger: 'change' },
                ]
            },
        };
    },
    methods: {
        ...mapActions("startDiagram", ["addAppletStartupDiagram"]),
        uploadSuccess(path) {
            this.form.pic = path;
            console.log(this.form.pic);
        },
        setStatus() {
            if (this.form.status) this.statusTxt = "启用";
            else this.statusTxt = "禁用";
        },
        async onSubmit() {
            this.$refs["form"].validate(async (valid) => {
                if (valid) {
                    this.loading = true
                    let form = JSON.parse(JSON.stringify(this.form))
                    form.status = this.form.status ? '1' : '2'
                    const { ret } = await this.addAppletStartupDiagram(form)
                    this.loading = false
                    if (ret == 0) {
                        this.$message.success('新增成功！')
                        this.$router.push('/startDiagram')
                    }
                }
            });
        },
        selectType(val) {
            this.form.type = val;
        },
        onSelect(image) {
            this.image = imageUrl + image.path;
            this.form.pic = image.path;
        },
        onDelete() {
            this.image = "";
        },
    },
};
</script>

<style scoped>

</style>
